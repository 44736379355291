import React, { Component } from 'react'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'

import Link from '../utils/link'
import { LeftArrow, Mail, Logo, Search, Close } from './icons'
import PopupModal from './popup-modal'
import ActiveCampaignForm from './active-campaign-form'
import useLatestPost from '../hooks/use-latest-post'
import ArticleCard from './cards/article'
import { GatsbyImage } from 'gatsby-plugin-image'

const ProductTitle = 'Products'
const CompanyTitle = 'About'

const BottomBarMegaMenu = ({ el, linkProps }) => {

  const { bottomBarButtons } = el

  return (
    <div className='header__dropdown-wrapper-bottom'>
      <div className='header__dropdown-wrapper-bottom-inner'>
        <div className='header__dropdown-wrapper-bottom-content'>
          {bottomBarButtons?.filter(el => el.alignment === 'left')?.map((item, i) => (
            <Link key={i} to={item.page.url} title={item.page.title} target={item.page.target} className={item.buttonType === 'button' ? 'btn' : 'default'} {...linkProps}>
              <span>{item.page.title}</span>
            </Link>
          ))}
        </div>
        <div className='header__dropdown-wrapper-bottom-content'>
          {bottomBarButtons?.filter(el => el.alignment === 'right')?.map((item, i) => (
            <Link key={i} to={item.page.url} title={item.page.title} target={item.page.target} className={item.buttonType === 'button' ? 'btn' : 'default'} {...linkProps}>
              <span>{item.page.title}</span>
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

let lastMousePos = { x: -1, y: -1 }

const ProductsMegaMenu = ({ el, linkProps }) => {

  const [currCol, setCurrCol] = React.useState(0)

  const latestPost = useLatestPost()

  const { menuItems, showFeaturedArticle, featuredArticle } = el

  const selectCollection = (index) => (event) => {
    // get mouse position
    const x = event.clientX
    const y = event.clientY

    if (lastMousePos.x !== -1 && Math.abs(lastMousePos.x - x) > 20) {
      lastMousePos = { x, y }
      return
    }

    lastMousePos = { x, y }
    // set column index
    setCurrCol(index)
  }

  let articleContent = null
  if (showFeaturedArticle) {
    switch (featuredArticle?.__typename) {
      case 'WpPost':
        articleContent = (
          <div className='header__dropdown-content'>
            <div className='header__dropdown-content-article'>
              <ArticleCard {...featuredArticle} />
            </div>
          </div>
        )
        break;
      default:
        articleContent = (
          <div className='header__dropdown-content'>
            <div className='header__dropdown-content-article'>
              <ArticleCard {...latestPost} />
            </div>
          </div>
        )
        break;
    }
  }

  return (
    <>
      <div className='header__dropdown-content'>
        <h5>
          <Link to={el.link.url} title={el.link.title} {...linkProps}>
            <span>See all: {el.link.title} →</span>
          </Link>
        </h5>
        <ul>
          {menuItems?.map((item, i) => (
            <li key={i}>
              <Link 
                to={item.collection.url} 
                title={item.collection.title} 
                {...linkProps} 
                onMouseEnter={selectCollection(i)} 
                className={currCol === i ? 'active' : ''}
                onClick={e => {
                  e.preventDefault()
                  setCurrCol(i)
                }}>
                <span>{item.collection.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className='header__dropdown-content'>
        <h5>
          <Link to={menuItems[currCol]?.collection.url} title={menuItems[currCol]?.collection.title} {...linkProps}>
            <span>See all: {menuItems[currCol]?.collection.title} →</span>
          </Link>
        </h5>
        <ul className='long-list'>
          {menuItems[currCol]?.inneritems?.map((item, i) => item?.link?.url && (
            <li key={i}>
              <Link to={item.link.url} title={item.link.title} {...linkProps}>
                <span>{item.link.title}</span>
              </Link>
            </li>
          )).filter(el => el)}
        </ul>
      </div>
      { articleContent }
    </>
  )
}

const BrandsMegaMenu = ({ el, linkProps, isActive }) => {

  const [currBrand, setCurrBrand] = React.useState(-1)

  const latestPost = useLatestPost()

  React.useEffect(() => {
    if (!isActive) {
      setCurrBrand(-1)
    }
  }, [isActive])

  const { singleMenuList, showFeaturedArticle, featuredArticle } = el

  let articleContent = null
  if (showFeaturedArticle) {
    switch (featuredArticle?.__typename) {
      case 'WpPost':
        articleContent = (
          <div className='header__dropdown-content'>
            <div className='header__dropdown-content-article'>
              <ArticleCard {...featuredArticle} />
            </div>
          </div>
        )
        break;
      default:
        articleContent = (
          <div className='header__dropdown-content'>
            <div className='header__dropdown-content-article'>
              <ArticleCard {...latestPost} />
            </div>
          </div>
        )
        break;
    }
  }

  return (
    <>
      <div className='header__dropdown-content'>
        <div className='header__dropdown-content-image'>
          { singleMenuList[currBrand]?.featuredImage?.node.localFile.childImageSharp.gatsbyImageData && (
            <GatsbyImage image={singleMenuList[currBrand]?.featuredImage?.node.localFile.childImageSharp.gatsbyImageData} alt={singleMenuList[currBrand]?.title} />
          )}
        </div>
      </div>
      <div className='header__dropdown-content'>
        <h5>
          <Link to={el.link.url} title={el.link.title} {...linkProps}>
            <span>See all: {el.link.title} →</span>
          </Link>
        </h5>
        <ul className='long-list'>
          {singleMenuList?.map((item, i) => (
            <li key={i}>
              <Link to={item.uri} title={item.title} {...linkProps} onMouseEnter={() => setCurrBrand(i)}>
                <span>{item.title}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      { articleContent }
    </>
  )
}

class Header extends Component {

  lastDirection = 0

  constructor(props) {
    super(props)
    this.searchRef = React.createRef()

    const machines = process.env.GATSBY_WP_CATEGORY_SITE_SLUG === 'new-zealand' ? this.props.data.machinesNz.nodes : this.props.data.machinesAu.nodes
    const machineNodes = this.props.data.machineMenu.nodes.filter(item => !!machines?.find(machine => machine.uri === item.uri))
    machineNodes.sort((a, b) => a.order - b.order)

    this.state = {
      offCanvas: false,
      popup: false,

      // Header status
      scrolled: false,
      backgroundFill: false,

      // Old Menu status
      productsNav: false,
      companyNav: false,
      firstSub: null,
      secondSub: null,

      // New Menu status (index of active menu item)
      menuActive: -1,

      // Search status
      searchActive: false,
      searchInput: '',
      machineMenu: { nodes: machineNodes },
    }
  }

  componentDidMount() {
    window._toggleOffCanvas = this.toggleOffCanvas
    window._togglePopup = this.togglePopup

    window.addEventListener('click', this.outsideClick)
    window.addEventListener('scroll', this.throttleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.outsideClick)
    window.removeEventListener('scroll', this.throttleScroll)
  }

  outsideClick = (event) => {
    const { target } = event
    if (target.closest('.header')) return
    this.setState({ menuActive: -1 })
  }

  throttleScroll = () => {
    if (!this.ticking) {
      requestAnimationFrame(this.handleScroll)
    }
    this.ticking = true
  }

  handleScroll = () => {
    this.setState({
      scrolled: this.lastDirection < window.scrollY && window.scrollY > 200,
      backgroundFill: window.scrollY > 0
    })
    this.lastDirection = window.scrollY
    this.ticking = false
  }

  hideOffCanvas = () => {
    this.setState({ offCanvas: false, searchActive: false, menuActive: -1 })
  }

  toggleOffCanvas = () => {
    document.body.classList.toggle('no-scroll', !this.state.offCanvas)
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  togglePopup = () => {
    document.body.classList.toggle('no-scroll', !this.state.popup)
    this.setState({ popup: !this.state.popup, searchActive: false })
  }

  onSubmit = (event) => {
    event.preventDefault()
  }

  resetHeader = () => {
    this.setState({
      productsNav: false,
      companyNav: false,
      firstSub: null,
      secondSub: null,
      offCanvas: false,
    })
  }

  render() {
    let {
      offCanvas, scrolled, backgroundFill, popup, machineMenu,
      companyNav, productsNav, firstSub, secondSub, searchActive, menuActive
    } = this.state

    const { headerMenu, companyMenu, wp } = this.props.data

    const props = {
      onClick: this.hideOffCanvas,
      activeClassName: 'active'
    }

    const deactivateNavProps = {
      activeClassName: 'active',
      onClick: (e) => {
        const title = e.target.innerText

        if (window.innerWidth < 900) {
          // Mobile 

          if (title === ProductTitle) {
            e.preventDefault()
            return this.setState({ productsNav: true })
          }
          if (title === CompanyTitle) {
            e.preventDefault()
            return this.setState({ companyNav: true })
          }

        } else {
          // Desktop

          if (e.currentTarget.dataset.type !== 'none') {
            e.preventDefault()

            const title = e.target.innerText

            let menuActive = this.props.data?.wp?.siteOptions?.acf?.headerMenu?.findIndex((el) => el.link.title === title)
    
            if (menuActive === -1) {
              menuActive = this.props.data?.wp?.siteOptions?.acf?.rhsMenu?.findIndex((el) => el.link.title === title)
    
              if (menuActive !== -1) {
                menuActive += this.props.data?.wp?.siteOptions?.acf?.headerMenu?.length
              }
            }

            return this.setState({ menuActive })
          }
        }
        this.hideOffCanvas()
      },
    }

    let headerClass = 'header'
    if (scrolled && (!productsNav || !companyNav) && !popup) headerClass += ' header--scrolled'
    if (backgroundFill || productsNav || companyNav || menuActive > -1) headerClass += ' header--background-fill'
    if (searchActive) headerClass += ' header--search'
    if (offCanvas) headerClass += ' header--off-canvas'

    const firstSubList = machineMenu?.nodes?.filter(el => el.parentId === firstSub?.id)
    const secondSubList = machineMenu?.nodes?.filter(el => el.parentId === secondSub?.id)

    return (
      <>
        <header className={headerClass} onMouseLeave={() => this.setState({ productsNav: false, companyNav: false })}>

          {wp.siteOptions.acf.announcement &&
            <div className='header__announcement'>
              <p>{parse(wp.siteOptions.acf.announcement)}</p>
            </div>
          }

          <div className='header__inner'>
            <Link to='/' title='Headland' className='header__logo' {...props}>
              <Logo />
            </Link>

            <nav>
              {wp?.siteOptions?.acf?.headerMenu?.map((el, i) => {

                return (
                  <Link key={i} to={el.link.url} data-type={el.type} title={el.link.title} {...deactivateNavProps}>
                    <span>{el.link.title}</span>

                    {
                      el.type === 'basic' && (
                        <div className={`header__dropdown header__dropdown-basic ${this.state.menuActive === i ? 'active' : ''}`}>
                          <div className='header__dropdown-background' />
                          <div className='header__dropdown-list'>
                            <h5>
                              <Link to={el.link.url}>
                                {el.link.title}
                              </Link>
                            </h5>
                            <ul>
                              {el.dropdownItems?.map((item, i) => (
                                <li key={i}>
                                  <Link to={item.link.url} title={item.link.title} {...props}>
                                    <span>{item.link.title}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )
                    }
                  </Link>
                )
              })}
            </nav>

            <div className='header__cta'>

              <div className='header__cta-links'>
                { wp.siteOptions.acf.rhsMenu?.map((el, i) => (
                  <Link 
                    to={el.link.url} 
                    className='header__cta-links-text'
                    title={el.link.title}
                    data-type={el.type}
                    {...(el.type === 'basic' ? (deactivateNavProps) : (props))}
                  >
                    <span>{ el.link.title }</span>
                    {
                      el.type === 'basic' && (
                        <div className={`header__dropdown header__dropdown-basic ${this.state.menuActive === i + this.props.data?.wp?.siteOptions?.acf?.headerMenu?.length ? 'active' : ''}`}>
                          <div className='header__dropdown-background' />
                          <div className='header__dropdown-list'>
                            <h5>
                              <Link to={el.link.url}>
                                {el.link.title}
                              </Link>
                            </h5>
                            <ul>
                              {el.dropdownItems?.map((item, i) => (
                                <li key={i}>
                                  <Link to={item.link.url} title={item.link.title} {...props}>
                                    <span>{item.link.title}</span>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )
                    }
                  </Link>
                ))}

                <Link
                  to='/contact-us/'
                  className='header__cta-links-icon'
                  {...props}
                >
                  <Mail color="#00D991" />
                </Link>
              </div>

              <div className='header__search'>
                <button
                  type='button'
                  onClick={() => {
                    if (window.innerWidth < 900) {
                      return navigate('/search/')
                    }
                    if (!searchActive) {
                      setTimeout(() => this.searchRef.current.focus(), 300)
                    }
                    this.setState({ searchActive: !searchActive })
                  }}>
                  <Search />
                </button>
                <form action='/search/' onSubmit={() => console.log('Submit search')}>
                  <input
                    ref={this.searchRef}
                    type='text'
                    name='q'
                    placeholder='What are you looking for…'
                    required
                  />
                </form>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => this.setState({ searchActive: false })}>
                  <Close />
                </button>
              </div>

              <button type='button' className={`header__off-canvas-toggle ${offCanvas ? 'active' : ''}`} onClick={this.toggleOffCanvas}>
                <svg viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g>
                    <line x1="0" y1="14" x2="48" y2="14" strokeWidth="5" />
                    <line x1="0" y1="34" x2="48" y2="34" strokeWidth="5" />
                  </g>
                  <g>
                    <line x1="0" y1="24" x2="48" y2="24" strokeWidth="5" />
                    <line x1="0" y1="24" x2="48" y2="24" strokeWidth="5" />
                  </g>
                </svg>
              </button>

            </div>
          </div>

          {wp.siteOptions.acf.headerMenu?.map((el, i) => {
            if (el.type !== 'mega') return null
            const isActive = this.state.menuActive === i


            let content = null
            if (el.menuType === 'products') {
              content = <ProductsMegaMenu el={el} linkProps={props} isActive={isActive} />
            }

            if (el.menuType === 'brand') {
              content = <BrandsMegaMenu el={el} linkProps={props} isActive={isActive} />
            }

            // el.type === 'mega'
            return (
              <div key={i} className={`header__dropdown ${isActive ? 'active' : ''}`}>
                <div className='header__dropdown-background' />
                <div className='header__dropdown-wrapper'>
                  <div className='header__dropdown-wrapper-main'> 
                    { content }
                  </div>
                  { el.showBottomBar && <BottomBarMegaMenu el={el} linkProps={props} /> }
                </div>
              </div>
            )
          })}

        </header>

        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
          <div className='off-canvas__inner'>
            <div>
              <nav className={productsNav ? '' : 'active'}>
                {headerMenu?.menuItems?.nodes?.map((el, i) => (
                  <Link key={i} to={el.uri} title={el.label} {...deactivateNavProps}>
                    <span>{el.label}</span>
                    <LeftArrow />
                  </Link>
                ))}
              </nav>

              <nav className={companyNav ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ companyNav: false })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link to='/company/' className='back-btn' onClick={this.resetHeader}>
                  <span>Overview Company</span>
                </Link>
                {companyMenu?.nodes?.filter(el => el.parentId === null).map((el, i) => (
                  <Link to={el.uri} key={i}
                    onClick={(event) => {
                      this.resetHeader()
                    }}
                  >
                    <span>{el.label}</span>
                    {companyMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow />}
                  </Link>
                ))}
              </nav>

              <nav className={productsNav && !firstSub ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ productsNav: false })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link to='/products/' className='back-btn' onClick={this.resetHeader}>
                  <span>Overview Products</span>
                </Link>
                {machineMenu?.nodes?.filter(el => el.parentId === null).map((el, i) => (
                  <Link to={el.uri} key={i}
                    onClick={(event) => {
                      if (machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0) {
                        event.preventDefault()
                        this.setState({ firstSub: el, secondSub: null })
                      } else {
                        this.resetHeader()
                      }
                    }}
                  >
                    <span>{el.label}</span>
                    {machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow />}
                  </Link>
                ))}
              </nav>

              <nav className={firstSub && !secondSub ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ firstSub: null })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link
                  to={firstSub?.uri} className='back-btn'
                  onClick={this.resetHeader}
                >
                  {`Overview ${firstSub?.label}`}
                </Link>
                {firstSubList.map((el, i) => (
                  <Link to={el.uri} key={i}
                    onClick={(event) => {
                      if (machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0) {
                        event.preventDefault()
                        this.setState({ secondSub: el })
                      } else {
                        this.resetHeader()
                      }
                    }}
                  >
                    <span>{el.label}</span>
                    {machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow />}
                  </Link>
                ))}
              </nav>

              <nav className={secondSub ? 'active' : ''}>
                <button type='button' onClick={() => this.setState({ secondSub: null })} className='back-btn'>
                  <LeftArrow />
                  <span>Back</span>
                </button>
                <Link
                  to={secondSub?.uri} className='back-btn'
                  onClick={this.resetHeader}
                >
                  {`Overview ${secondSub?.label}`}
                </Link>
                {secondSubList.map((el, i) => (
                  <Link
                    to={el.uri} title={el.label} key={i}
                    //onMouseEnter={() => this.setState({ firstSub: el, secondSub: null })}
                    onClick={this.resetHeader}
                  >
                    <span>{el.label}</span>
                    {machineMenu?.nodes?.filter(a => a.parentId === el.id).length > 0 && <LeftArrow />}
                  </Link>
                ))}
              </nav>

            </div>
          </div>
        </div>

        <PopupModal active={popup}>
          {wp.siteOptions.acf.contactPopupText && <div>{parse(wp.siteOptions.acf.contactPopupText)}</div>}
          {popup && <ActiveCampaignForm formId={11} />}
        </PopupModal>

      </>
    )
  }
}

export default Header
